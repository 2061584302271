import React, { useState } from "react";
import "./Header.scss";
import { data } from "../../locData";
import { FiMenu } from "react-icons/fi";
import SideBar from "../sideBar/SideBar.tsx";
import { useNavigate } from "react-router-dom";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="top-header">
        <div className="maxwidthHead">
          {/* <BsInstagram className="icons" size={20} /> */}
          <i className="fa-brands fa-instagram"></i>
          {/* <AiOutlineGooglePlus className="icons" size={20} /> */}
          <i className="fa-brands fa-google-plus-g"></i>
          {/* <BiLogoFacebook className="icons" size={20} /> */}
          <i className="fa-brands fa-facebook-f"></i>
          {/* <BsTwitter className="icons" size={20} /> */}
          <i className="fa-brands fa-twitter"></i>
        </div>
      </div>
      <div className="main-header">
        <div className="main-header maxWidthHeaderMain">
          <a href="index.html">
            <div className="logo-img">
              <img src={data.header.logo} alt="logo" />
            </div>
          </a>
          <FiMenu
            className="menu-icon icons"
            size={80}
            onClick={handleShowOffcanvas}
          />
          <SideBar show={showOffcanvas} handleClose={handleCloseOffcanvas} />

          <div className="nav-links">
            <ul className="header-ul">
              <li role="button" onClick={() => navigate("/")}>
                Home
              </li>
              <li role="button" onClick={() => navigate("/contact-us")}>
                Contact Us
              </li>
              <li role="button" onClick={() => navigate("/gallery")}>
                Gallery
              </li>
              {data.home.ctas.map((cta, index) => (
                <li role="button" key={index} className="header-cta">
                  <a href={cta.link} rel="noreferrer" target="_blank">
                    {cta.ctaButton}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
