import React from "react";
import "./GalleryComponent.scss";
import { data } from "../../locData";

interface GalleryComponentProps {}

const GalleryComponent: React.FC<GalleryComponentProps> = () => {
  return (
    <div className="gallery-main">
      <div className="maxWidth-gallery">
        <p className="gallery-text">Gallery</p>
        <div className="gallery-main-div">
          {data.gallery.map((imageUrl, index) => (
            <img
              src={imageUrl}
              className="gall-img"
              key={index}
              alt={`gallery-img-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryComponent;
