import React from "react";
import "./Footer.scss";
import { BiMinus } from "react-icons/bi";
import { data } from "../../locData";
import card1 from "../../assets/images/card-1.png";
import card2 from "../../assets/images/card-2.png";
import card3 from "../../assets/images/card-3.png";
import card4 from "../../assets/images/card-4.png";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const cardPayment = [card1, card2, card3, card4];
  return (
    <React.Fragment>
      <div className="main-Footer">
        <div className="maxWidth">
          <div className="contact-businessmain">
            <div className="footer-contact">
              <p className="head-text">Contact Us</p>
              <div className="tel-number">
                {data.aboutUs.telNum.map((telData, index) => (
                  <p className="tel-text" key={index}>
                    <a href={"tel:" + telData}> TEL: {telData}</a>
                  </p>
                ))}
              </div>
            </div>
            <div className="business-hours">
              <p className="head-text">Business Hours</p>
              {data.businessHours.map((timeSlot, index) => (
                <div className="timeslot" key={index}>
                  <div>
                    <BiMinus color="#999999" size={20} />
                  </div>
                  <div>
                    {timeSlot.title}
                    <br />
                    <pre>{timeSlot.hours}</pre>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="payment-images">
            {cardPayment.map((paymentImg, index) => (
              <img
                src={paymentImg}
                alt="paymentimg"
                key={index}
                className="payment-img"
              />
            ))}
          </div>
          <div className="copyright">
            <p className="copyright-text">
              {`Copyright ${data.home.nameRestaurent} All Right Reserved. Powered by`}
              <span
                className="highlight-text"
                role="button"
                onClick={() => {
                  window.location.href = "https://www.kloudeats.com";
                }}
              >
                {" "}
                Kloudeats
              </span>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
