import React from "react";
import "./MainContact.scss";
import { data } from "../../locData";

interface MainContactProps {}

const MainContact: React.FC<MainContactProps> = () => {
  return (
    <div className="main-contact">
      <div className="maxWidthMainContact">
        <p className="contact-text">Contact Us</p>
        <div className="resto-details">
          {data.aboutUs.telNum.map((phone, index) => (
            <p className="tel-yellow" key={index}>
              <a href={"tel:" + phone}> Tel: {phone} </a>
            </p>
          ))}
          <p className="address">{data.contactUs.address}</p>
          <div>
            <div className="lunch-div">
              <p className="italic-text">Hours</p>
              {data.businessHours.map((timeSlot, index) => (
                <p className="timing-text-main" key={index}>
                  {timeSlot.title}
                  <br />
                  <pre>{timeSlot.hours}</pre>
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5054%20Park%20Ave,%20Memphis,%20TN%2038117%20%20+(My%20Business%20Name)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            title="location"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden={false}
            tabIndex={0}
          ></iframe>
        </div>
        <div className="border"></div>
      </div>
    </div>
  );
};

export default MainContact;
