import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel.scss";
import { data } from "../../locData";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface CarouselComponentProps {}

const CarouselComponent: React.FC<CarouselComponentProps> = () => {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      infinite={true}
      customTransition="all .5"
      transitionDuration={500}
    >
      {data.home.slider.map((imageUrl, index) => (
        <div key={index}>
          <img src={imageUrl} alt={`slider-img-${index}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
