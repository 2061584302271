import React from "react";
import Header from "../header/Header.tsx";
import CarouselComponent from "../carousel/Carousel.tsx";
import "./mainHome.scss";
import { data } from "../../locData";
import Footer from "../footer/Footer.tsx";

const MainHome: React.FC = () => {
  const images1 = [data.home.featured[0], data.home.featured[1]];
  const images2 = [data.home.featured[2], data.home.featured[3]];

  return (
    <div className="mainHome">
      <Header />
      <CarouselComponent />
      <div className="home-main">
        <div className="orderNow-div">
          {data.home.ctas.map((cta, index) => (
            <div key={index}>
              <h3 className="restaurant-name">{cta.title}</h3>
              <a href={cta.link} rel="noreferrer" target="_blank">
                <button className="big-online-btn">{cta.ctaButton}</button>
              </a>
            </div>
          ))}
        </div>
        <div className="about-us-main">
          <div className="about-us-main maxWidth">
            <div className="about-us">
              <p className="about-text">About Us</p>
              <h2 className="welcome-text">{data.aboutUs.welcomeText}</h2>
              <p className="name-text">{data.home.nameRestaurent}</p>
              <div className="telephone">
                {data.aboutUs.telNum.map((telData, index) => (
                  <p key={index}>
                    <a href={"tel:" + telData}>TEL {telData}</a>
                  </p>
                ))}
              </div>
              <div className="description">{data.aboutUs.description}</div>
              <div className="timing">
                {data.businessHours.map((timeSlot, index) => (
                  <>
                    <p className="italic">{timeSlot.title}</p>
                    <pre key={index}>{timeSlot.hours}</pre>
                  </>
                ))}
              </div>
            </div>
            <div className="images-gallery">
              <p className="enjoy-text">Enjoy</p>
              <p className="the-text">The</p>
              <p className="delicious-text">Delicious</p>
              <div className="gallery">
                <div className="gall-row">
                  {images1.map((imgData, index) => (
                    <div className="gallery-img" key={index}>
                      <img src={imgData} alt="food" />
                    </div>
                  ))}
                </div>
                <div className="gall-row">
                  {images2.map((imgData, index) => (
                    <div className="gallery-img" key={index}>
                      <img src={imgData} alt="food" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainHome;
