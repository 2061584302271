import React from "react";
import Header from "../../components/header/Header.tsx";
import MainContact from "../../components/mainContact/MainContact.tsx";
import CommonFooter from "../../components/commonFooter/CommonFooter.tsx";

const ContactUs: React.FC = () => {
  return (  
    <React.Fragment>
      <Header />
      <MainContact />
      <CommonFooter />
    </React.Fragment>
  );
};

export default ContactUs;
