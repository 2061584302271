import React from "react";
import { data } from "../../locData";

const CommonFooter: React.FC = () => {
  const navigateToKloudEats = () => {
    window.location.href = 'https://www.kloudeats.com'
  };
  
  return (
    <React.Fragment>
      <div className="main-Footer commonFooter">
        <div className="maxWidth">
          <div className="copyright">
            <p className="copyright-text">
              {`Copyright ${data.home.nameRestaurent} All Right Reserved. Powered by`}
              <span
                className="highlight-text"
                onClick={navigateToKloudEats}
                role="button"
              >
                {" "}
                Kloudeats
              </span>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonFooter;
