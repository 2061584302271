import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./Sidebar.scss";
import { data } from "../../locData";
import { useNavigate } from "react-router-dom";

interface SideBarProps {
  show: boolean;
  handleClose: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ show, handleClose }) => {
  const navigate = useNavigate();

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        backdrop={true}
        scroll={true}
        className="main-sidebar"
        style={{
          width: "240px",
          padding: 0,
          margin: 0,
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>

        <div className="main">
          <ul className="sidebar-ul">
            <li
              className="sidebar-li"
              role="button"
              onClick={() => navigate("/")}
            >
              Home
            </li>
            <li
              className="sidebar-li"
              role="button"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </li>
            <li
              className="sidebar-li"
              role="button"
              onClick={() => navigate("/gallery")}
            >
              Gallery
            </li>
            {data.home.ctas.map((cta, index) => (
              <li role="button" className="btn-order sidebar-li" key={index}>
                <a href={cta.link} target="_blank" rel="noreferrer">
                  {cta.ctaButton}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Offcanvas>
    </>
  );
};

export default SideBar;
