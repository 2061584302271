import React from "react";
import Header from "../../components/header/Header.tsx";
import GalleryComponent from "../../components/gallery/GalleryComponent.tsx";
import CommonFooter from "../../components/commonFooter/CommonFooter.tsx";

const Gallery: React.FC = () => {
  return (
    <div>
      <Header />
      <GalleryComponent />
      <CommonFooter />
    </div>
  );
};

export default Gallery;
