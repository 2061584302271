import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home.tsx";
import Gallery from "./pages/gallery/Gallery.tsx";
import ContactUs from "./pages/contactUs/ContactUs.tsx";

const App: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export default App;
