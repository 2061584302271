export const data = {
  header: {
    logo: "./images/logo.png",
  },
  home: {
    nameRestaurent: "Sushi Kingdom",
    slider: [
      "./images/slide-bg1.jpg",
      "./images/slide-bg2.jpg",
      "./images/slide-bg3.jpg",
    ],
    featured: [
      "./images/gall1.jpg",
      "./images/gall2.jpg",
      "./images/gall3.jpg",
      "./images/gall4.jpg",
    ],
    ctas: [
      {
        title: "Sushi Kingdom",
        ctaButton: "Order Sushi",
        link: "https://order.sushikingdommemphis.com/",
      },
      {
        title: "The Juicy Crab",
        ctaButton: "Order Seafood Boil",
        link: "https://order.kloudeats.com/l/the-juicy-crab-memphis/181",
      },
    ],
  },
  gallery: [
    "./images/food01.jpg",
    "./images/food02.jpg",
    "./images/food03.jpg",
    "./images/food04.jpg",
    "./images/food05.jpg",
    "./images/food06.jpg",
    "./images/food07.jpg",
    "./images/food08.jpg",
  ],
  aboutUs: {
    welcomeText: "WELCOME TO Sushi Kingdom",
    description:
      "Located at 5054 Park Avenue, Memphis, TN 38117 We are dedicated to serve the finest and freshest foods.Welcome you to the ordering and eating.",
    aboutUsImg: "",
    telNum: ["901-746-8786", "901-748-5436"],
  },
  businessHours: [
    {
      title: "LUNCH",
      hours: "Mon - Thur: 11am-2:30pm"
    },
    {
      title: "DINNER",
      hours: "Mon - Thur: 4:30pm-10pm\nFri - Sat: 11am-10:30pm\nSun: 12noon-10pm\nSat - Sun: All Day Dinner"
    },
  ],

  socialIcons: [
    {
      icon: "",
    },
    {
      link: "",
    },
  ],
  contactUs: {
    address: "5054 Park Avenue, Memphis, TN 38117",
    map: "",
  },
  reviews: {
    text: "reviews",
    subText: "",
    reviews: [
      {
        name: "Wilson",
        review: "It was goood",
      },
      {
        name: "Wilson",
        review: "It was goood",
      },
    ],
  },
};
